import React from 'react'
import { PaginaType } from 'utils/types'
import styles from './HomeTemplate.module.sass'
import {
  Page,
  ArticoliCorrelati,
  BoxHeroListingCard,
  HeroSlider,
  SliderMiglioriProdotti,
  StrumentiLinkUtili,
  HeroWithBox,
} from 'components/organisms'

import {
  articoliCorrelatiMenu,
  articoliGenericiiMenu,
  MenuHeroSlider,
  MenuMiglioriProdotti,
  MenuStrumentiLinkUtili,
  MenuAlimentazioneSostenibile,
} from 'utils/mock'
import { WContainer } from 'components/atoms'
import { useSelector } from 'react-redux'

interface Props {
  pagina: PaginaType
}

const HomeTemplate = (props: Props) => {
  const { pagina } = props
  const { menu } = useSelector((state) => state.menu)

  const hpmenu = menu?.find((item) => item.key === 'hp')
  const boxHeroCards = hpmenu?.children.find((item) => item.key === 'box_hero_listing_card')
  const heroSlider = hpmenu?.children.find((item) => item.key === 'hero_slider')
  const sliderCorrelatiTop = hpmenu?.children.find((item) => item.key === 'slider_correlati_top')
  const heroWithBox = hpmenu?.children.find((item) => item.key === 'hero_with_box')
  const strumentilinkUtili = hpmenu?.children.find((item) => item.key === 'strumenti_link_utili')
  const sliderCorrelatiBottom = hpmenu?.children.find((item) => item.key === 'slider_correlati_bottom')
  const miglioriProdotti = hpmenu?.children.find((item) => item.key === 'migliori_prodotti') 


  return (
    <Page pagina={pagina}>
      <div className={styles.homeTemplate}>
        <BoxHeroListingCard item={boxHeroCards} />
        <div className={styles.articoli__container}>
          <WContainer>
            <div className={styles.heroSlider__container}>
              <HeroSlider item={heroSlider} page="homepage" />
            </div>
            <div className={styles.articoliCorrelati}>
              <ArticoliCorrelati item={sliderCorrelatiTop} />
            </div>
          </WContainer>
          <div className={styles.HeroWithBox__container}>
            <HeroWithBox item={heroWithBox} />
          </div>
        </div>
        <div className={styles.light__container}>
          <WContainer variant="sm">
            <StrumentiLinkUtili item={strumentilinkUtili} className={styles.strumentiLinkUtili} />
          </WContainer>
          <WContainer>
            <div className={styles.miglioriProdotti__container}>
              <SliderMiglioriProdotti item={miglioriProdotti} />
            </div>
            <div className={styles.articoliCorrelati__bottom}>
              <ArticoliCorrelati item={sliderCorrelatiBottom} />
            </div>
          </WContainer>
        </div>
      </div>
    </Page>
  )
}

export default HomeTemplate
