import React from 'react'
import { initializeApollo } from 'gql/client'
import { PAGINA } from 'utils/queries'
import HomeTemplate from 'components/templates/HomeTemplate/HomeTemplate'
import { NextWrapper } from 'data-fetching-wrapper'

const HomePage = ({ pagina }) => <HomeTemplate pagina={pagina} />

export const getStaticProps = NextWrapper.getStaticProps(async ({ locale }) => {
  const client = initializeApollo()

  const {
    data: { pagina },
  } = await client.query({
    query: PAGINA,
    variables: { chiave: 'homepage' },
  })

  if (!pagina) {
    return {
      notFound: true,
    }
  }

  return {
    props: { pagina }, // will be passed to the page component as props
    revalidate: 60 * 2, // In seconds
  }
}, '/')

export default HomePage
